import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { toFixedIfNecessary } from '@/helpers';
import store from '@/store';
import client from '@/client'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Membership',
    component: () => import('@/views/Membership.vue'),
    beforeEnter(to: any, from: any, next: any) {
      if (store.getters.isUserLoggedIn && !store.getters.hasFailedCheckout) {
        next({ name: 'Dashboard' })
      }
      else next();
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    beforeEnter(to: any, from: any, next: any) {
      if (store.getters.isUserLoggedIn) {
        client
        .getProfile(store.state.user.token)
        .then(response => {
          store.commit('setAnsweredDiscovery', response.data.how_discovered_us)
          store.commit('setTreesPlanted', Math.floor(response.data.balance.trees))
          store.commit('setCarbonOffset', toFixedIfNecessary(response.data.balance.carbon, 2))
          store.commit('setMembership', { name: response.data.plan, months_subscribed: response.data.months_subscribed })
        })
        next();
      }
      else if (store.getters.hasFailedCheckout) {
        next({ name: 'Membership' });
      }
      else next({ name: 'Login' });
    },
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/discovery',
    name: 'Discovery',
    component: () => import('@/views/Discovery.vue'),
    beforeEnter(to: any, from: any, next: any) {
      if (store.state.answeredDiscovery) {
        next({ name: 'Dashboard' });
      } else {
        next();
      }
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
