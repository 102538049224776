<template>
  <router-view/>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({

})

export default class App extends Vue {}
</script>

<style lang="scss">
@import '@/design';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0A290A;
}

html, body {
  background: #fbf8f4;
  margin: 0;
  padding: 0;
}
</style>
