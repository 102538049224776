import axios from 'axios';

const CLIENT = axios.create({
  baseURL: 'https://ourearth.io',
  timeout: 40000,
  headers: { 'Content-Type': 'application/json' },
  responseType: 'json',
  validateStatus: status => {
    //https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
    return status < 400;
  },
});

const AUTH_PATH = '/api/auth/';
const USER_PATH = '/api/user/';
const STRIPE_PATH = '/api/stripe/';

export default {
  URLS: {
    LOGIN: AUTH_PATH + 'login/',
    REGISTER: AUTH_PATH + 'register/',
    PROFILE: USER_PATH + 'profile/',
    CREATE_SUBSCRIPTION: STRIPE_PATH + 'create_subscription/',
    PRODUCTS: STRIPE_PATH + 'products/',
    HOW_DISCOVERED_US: USER_PATH + 'how_discovered_us/'
  },
  getProfile(token: string) {
    const url = this.URLS.PROFILE;
    return CLIENT.get(url, { headers: { Authorization: `Access ${ token }` } });
  },
  login(email: string, password: string, first_name: string, last_name: string) {
    const url = this.URLS.LOGIN;
    const auth = 'Basic ' + btoa(unescape(encodeURIComponent(email + ':' + password)));
    return CLIENT.post(url, { first_name, last_name, source: 'MEMBERSHIP' }, { headers: { Authorization: auth } });
  },
  register(email: string, password1: string, password2: string, first_name: string, last_name: string) {
    const url = this.URLS.REGISTER;
    return CLIENT.post(url, { email, password1, password2, first_name, last_name, source: 'MEMBERSHIP' });
  },
  getProducts() {
    const url = this.URLS.PRODUCTS;
    return CLIENT.get(url);
  },
  createSubscription(token: string, data: any) {
    const url = this.URLS.CREATE_SUBSCRIPTION;
    return CLIENT.post(url, data, { headers: { Authorization: `Access ${ token }` } });
  },
  getCountries() {
    return axios.get('https://restcountries.eu/rest/v2/all?fields=name;alpha2Code');
  },
  getClientCountry() {
    return axios.get('https://api.ipstack.com/check?access_key=89ae0a176fa62ff2a878c03f9fbd8d3c&fields=country_code');
  },
  submitDiscoveryAnswer(answer: string, token: string) {
    const url = this.URLS.HOW_DISCOVERED_US;
    return CLIENT.post(url, { how_discovered_us: answer }, { headers: { Authorization: `Access ${ token }` } });
  }
};
