import { createStore } from 'vuex'

interface User {
  email: string,
  token: string,
  stripe_id: string,
}

export const getState = function (name: string, type = 'localStorage') {
  // @ts-ignore
  const obj = window[type].getItem(name);
  if (obj) return JSON.parse(obj);
  else return null;
}

export default createStore({
  state: {
    user: getState('user'),
    treesPlanted: getState('treesPlanted'),
    carbonOffset: getState('carbonOffset'),
    failedCheckout: getState('failedCheckout'),
    membership: getState('membership'),
    answeredDiscovery: getState('answeredDiscovery'),
  },
  mutations: {
    loginUser(state, user: User) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    logoutUser(state) {
      state.user = null;
      localStorage.removeItem('user');
    },
    setTreesPlanted(state, trees) {
      state.treesPlanted = trees;
      localStorage.setItem('treesPlanted', trees);
    },
    setCarbonOffset(state, carbon) {
      state.carbonOffset = carbon;
      localStorage.setItem('carbonOffset', carbon);
    },
    failedCheckout(state, boolean) {
      state.failedCheckout = boolean;
      localStorage.setItem('failedCheckout', boolean);
    },
    setMembership(state, detail) {
      state.membership = detail;
      localStorage.setItem('membership', JSON.stringify(detail));
    },
    setAnsweredDiscovery(state, boolean) {
      state.answeredDiscovery = boolean;
      localStorage.setItem('answeredDiscovery', boolean);
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    isUserLoggedIn(state) {
      if (state.user && state.user.token) return true;
      else return false;
    },
    hasFailedCheckout(state) {
      if (String(state.failedCheckout) === 'true') {
        return true;
      }
      else return false;
    }
  }
})
